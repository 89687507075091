<template>
    <div>
        <b-form inline v-on:change="onFormChange">
            <label class="w-75" for="sys-numzone">Number of Zones:</label>
            <b-form-select
                id="sys-numzone"
                class="w-25"
                v-model="form.numZones"
                :options="numZonesOptions"
            ></b-form-select>
            <label class="w-75" for="sys-noz1Cnt">Zone 1 Count:</label>
            <b-form-input
                id="sys-noz1Cnt"
                class="w-25"
                type="number"
                v-model="form.noz1Count"
                required
            ></b-form-input>
            <label v-if="form.numZones === 2" class="w-75" for="sys-noz2Cnt">Zone 2 Count:</label>
            <b-form-input
                v-if="form.numZones === 2"
                id="sys-noz2Cnt"
                class="w-25"
                type="number"
                v-model="form.noz2Count"
                required
            ></b-form-input>
            <label class="w-75" for="sys-avgflow">Average Flow:</label>
            <b-form-input
                id="sys-avgflow"
                class="w-25"
                type="number"
                v-model="form.avgNozFlow"
                required
            ></b-form-input>
            <label class="w-75" for="sys-flowprime">Flow Prime:</label>
            <b-form-input
                id="sys-flowprime"
                class="w-25"
                type="number"
                v-model="form.flowPrime"
                required
            ></b-form-input>
            <label class="w-75" for="sys-clogdet">Clog / Leak Detection:</label>
            <b-form-input
                id="sys-clogdet"
                class="w-25"
                type="number"
                v-model="form.clogDet"
                required
            ></b-form-input>
            <label class="w-75" for="sys-clogtol">Clog Tolerance:</label>
            <b-form-input
                id="sys-clogtol"
                class="w-25"
                type="number"
                v-model="form.clogTol"
                required
            ></b-form-input>
            <label class="w-75" for="sys-leaktol">Leak Tolerance:</label>
            <b-form-input
                id="sys-leaktol"
                class="w-25"
                type="number"
                v-model="form.leakTol"
                required
            ></b-form-input>
            <label class="w-75" for="sys-noflow">No Flow Detection:</label>
            <b-form-input
                id="sys-noflow"
                class="w-25"
                type="number"
                v-model="form.noFlowDet"
                required
            ></b-form-input>
            <label class="w-75" for="sys-tankless">Tankless:</label>
            <b-checkbox
                id="sys-tankless"
                v-model="form.tankless"
                @change="tanklessChange"
            ></b-checkbox>
            <label class="w-75" for="sys-tank" v-if="!form.tankless">Tank Size:</label>
            <b-form-input
                v-if="!form.tankless"
                id="sys-tank"
                class="w-25"
                type="number"
                v-model="form.tankSize"
                required
            ></b-form-input>
            <label class="w-75" for="sys-mistd">Mist Default:</label>
            <b-form-input
                id="sys-mistd"
                class="w-25"
                type="number"
                v-model="form.mistDefault"
                required
            ></b-form-input>
            <label class="w-55" for="sys-cart1name" v-if="form.tankless">Cartridge 1:</label>
            <b-form-select
                id="sys-cart1name"
                class="w-75"
                v-model="form.c1name"
                :options="cartOptions"
                @change="cart1Change"
                v-if="form.tankless"
            ></b-form-select>
            <label class="w-75" for="sys-cart1size" v-if="showCart1">Size (Oz):</label>
            <b-form-input
                v-if="showCart1"
                id="sys-cart1size"
                class="w-25"
                type="number"
                v-model="form.c1size"
                required
            ></b-form-input>
            <label class="w-75" for="sys-cart1dose" v-if="showCart1">Dose:</label>
            <b-form-input
                v-if="showCart1"
                id="sys-cart1dose"
                class="w-25"
                type="number"
                v-model="form.c1dose"
                required
            ></b-form-input>
            <label class="w-55" for="sys-cart2name" v-if="form.tankless">Cartridge 2:</label>
            <b-form-select
                id="sys-cart2name"
                class="w-75"
                v-model="form.c2name"
                :options="cartOptions"
                v-if="form.tankless"
                @change="cart2Change"
            ></b-form-select>
            <label class="w-75" for="sys-cart2size" v-if="showCart2">Size (Oz):</label>
            <b-form-input
                v-if="showCart2"
                id="sys-cart2size"
                class="w-25"
                type="number"
                v-model="form.c2size"
                required
            ></b-form-input>
            <label class="w-75" for="sys-cart1dose" v-if="showCart2">Dose:</label>
            <b-form-input
                v-if="showCart2"
                id="sys-cart2dose"
                class="w-25"
                type="number"
                v-model="form.c2dose"
                required
            ></b-form-input>
            <label class="w-75" for="sys-agi" v-if="!form.tankless">Agitate:</label>
            <b-checkbox id="sys-agi" v-model="form.agitate" v-if="!form.tankless"></b-checkbox>
            <label class="w-75" for="sys-agid" v-if="!form.tankless">Agitation Duration:</label>
            <b-form-input
                id="sys-agid"
                class="w-25"
                type="number"
                v-model="form.agitateDur"
                v-if="!form.tankless"
                required
            ></b-form-input>
            <label class="w-50" for="sys-skiprain">Skip Rain:</label>
            <b-checkbox id="sys-skiprain" v-model="form.skipRain"></b-checkbox>
            <label class="w-50" for="sys-skipwind">Skip Wind:</label>
            <b-checkbox id="sys-skipwind" v-model="form.skipWind"></b-checkbox>
        </b-form>
    </div>
</template>

<script>
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "CfgSettings",
    data() {
        return {
            form: {
                numZones: 1,
                noz1Count: 32,
                noz2Count: 0,
                avgNozFlow: 42,
                flowPrime: 4,
                clogDet: 10,
                clogTol: 15,
                leakTol: 30,
                noFlowDet: 5,
                tankless: false,
                tankSize: 55,
                mistDefault: 30,
                agitate: false,
                agitateDur: 20,
                skipRain: false,
                skipWind: false,
                c1name: 'None',
                c1size: 0,
                c1dose: 0,
                c1type: 0,
                c2name: 'None',
                c2size: 0,
                c2dose: 0,
                c2type: 0
            },
            showCart1: false,
            showCart2: false,
            numZonesOptions: [
                {value: 1, text: "1"},
                {value: 2, text: "2"},
            ]
        }
    },
    created() {
        this.$store.dispatch('getCartridges');
    },
    mounted() {
        EventBus.$on("UpdateDeviceCfg", () => {
            const cfg = this.$store.getters.deviceCfg;
            this.form.numZones = cfg.numZones;
            this.form.noz1Count = cfg.noz1Count;
            this.form.noz2Count = cfg.noz2Count;
            this.form.avgNozFlow = cfg.avgNozFlow;
            this.form.flowPrime = cfg.flowPrime;
            this.form.clogDet = cfg.clogDet;
            this.form.clogTol = cfg.clogTol;
            this.form.leakTol = cfg.leakTol;
            this.form.noFlowDet = cfg.noFlowDet;
            this.form.tankless = cfg.tankless;
            this.form.tankSize = cfg.tankSize;
            this.form.mistDefault = cfg.mistDefault;
            this.form.agitate = cfg.agitate;
            this.form.agitateDur = cfg.agitateDur;
            this.form.skipRain = cfg.skipRain;
            this.form.skipWind = cfg.skipWind;
            if (this.form.tankless) {
                if (cfg.c1name === "")
                    cfg.c1name = "None";
                this.form.c1name = cfg.c1name;
                this.form.c1type = cfg.c1type;
                this.form.c1size = cfg.c1size;
                this.form.c1dose = cfg.c1dose;
                if (cfg.c2name === "")
                    cfg.c2name = "None";
                this.form.c2name = cfg.c2name;
                this.form.c2type = cfg.c2type;
                this.form.c2size = cfg.c2size;
                this.form.c2dose = cfg.c2dose;

                if (this.form.c1name !== 'None')
                    this.showCart1 = true;
                if (this.form.c2name !== 'None')
                    this.showCart2 = true;
            }
        });
        EventBus.$on("NewDeviceConfig", () => {
            this.$store.commit('storeDeviceCfg', this.form);
        });
        EventBus.$on("ConfigCompChange", () => {
            const cList = this.$store.getters.adminCatridges;
            let find1 = false;
            let find2 = false;
            for (let i = 0; i < cList.length; i++) {
                if (cList[i].company === this.$store.getters.compTemplateSel) {
                    if (cList[i].name === this.form.c1name) {
                        find1 = true;
                    }
                    if (cList[i].name === this.form.c2name) {
                        find2 = true;
                    }
                }
            }
            if (!find1) {
                this.form.c1name = 'None';
                this.showCart1 = false;
            }
            if (!find2) {
                this.form.c2name = 'None';
                this.showCart2 = false;
            }
        });
    },
    beforeDestroy() {
        EventBus.$off("UpdateDeviceCfg");
        EventBus.$off("NewDeviceConfig");
        EventBus.$off("ConfigCompChange");
    },
    methods: {
        onFormChange() {
            if (this.form.tankless)
                this.form.tankSize = 0;
            this.$store.commit('storeDeviceCfg', this.form);
            //console.log(this.form);
        },
        tanklessChange() {
            if (!this.form.tankless) {
                this.showCart1 = false;
                this.showCart2 = false;
            }
            else {
                this.cart1Change();
                this.cart2Change();
            }
        },
        cart1Change() {
            if (this.form.c1name === "" || this.form.c1name === 'None') {
                this.form.c1size = 0;
                this.form.c1dose = 0;
                this.form.c1type = 0;
                this.showCart1 = false;
            }

            const tempCarts = this.$store.getters.adminCatridges;
            for (let i = 0; i < tempCarts.length; i++) {
                if (tempCarts[i].company === this.$store.getters.compTemplateSel){
                    if (tempCarts[i].name === this.form.c1name) {
                        this.form.c1size = tempCarts[i].cSize;
                        this.form.c1dose = tempCarts[i].cDose;
                        this.form.c1type = tempCarts[i].cType;
                        this.showCart1 = true;
                    }
                }
            }
        },
        cart2Change() {
            if (this.form.c2name === "" || this.form.c2name === 'None') {
                this.form.c2size = 0;
                this.form.c2dose = 0;
                this.form.c2type = 0;
                this.showCart2 = false;
            }

            const tempCarts = this.$store.getters.adminCatridges;
            for (let i = 0; i < tempCarts.length; i++) {
                if (tempCarts[i].company === this.$store.getters.compTemplateSel){
                    if (tempCarts[i].name === this.form.c2name) {
                        this.form.c2size = tempCarts[i].cSize;
                        this.form.c2dose = tempCarts[i].cDose;
                        this.form.c2type = tempCarts[i].cType;
                        this.showCart2 = true;
                    }
                }
            }
        }
    },
    computed: {
        cartOptions() {
            const tempCarts = this.$store.getters.adminCatridges;
            //console.log(tempCarts);
            let carts = ['None'];
            if (tempCarts === null)
                return carts;
            for (let i = 0; i < tempCarts.length; i++) {
                if (tempCarts[i].company === this.$store.getters.compTemplateSel)
                    carts.push(tempCarts[i].name);
            }
            return carts;
        }
    }
}
</script>

<style scoped>

</style>
