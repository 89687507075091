<template>
    <div>
        <b-form v-on:change="onFormChange">
            <b-form-group
                id="sys-name-group"
                label="System Name:"
                label-for="sys-name"
            >
                <b-form-input
                    id="sys-name"
                    v-model="form.sysName"
                    :formatter="nameFormatter"
                    placeholder="Enter System Name"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="sys-status-group"
                label="System Status:"
                label-for="sys-status"
            >
                <b-form-select
                    id="sys-status"
                    v-model="form.sysStatus"
                    :options="sysStatusOptions"
                ></b-form-select>
            </b-form-group>
            <b-form-group
                id="sys-type-group"
                label="System Type:"
                label-for="sys-type"
            >
                <b-form-select
                    id="sys-type"
                    v-model="form.sysType"
                    :options="sysTypeOptions"
                ></b-form-select>
            </b-form-group>
            <b-form-group
                id="sys-comp-group"
                label="Company:"
                label-for="sys-comp"
            >
                <b-form-select
                    id="sys-comp"
                    v-model="form.company"
                    :options="sysCompOptions"
                    :disabled="compSelDisable"
                    @change="compSelChange"
                ></b-form-select>
            </b-form-group>
<!--            <b-form-group-->
<!--                id="sys-cust-group"-->
<!--                label="Customer:"-->
<!--                label-for="sys-cust"-->
<!--            >-->
<!--                <b-form-input-->
<!--                    id="sys-cust"-->
<!--                    v-model="form.customer"-->
<!--                    :formatter="nameFormatter"-->
<!--                    placeholder="Enter Customer Name"-->
<!--                    required-->
<!--                ></b-form-input>-->
<!--            </b-form-group>-->
        </b-form>
    </div>
</template>

<script>
import roles from '@/roles';
import { EventBus } from '@/plugins/event-bus';

export default {
    name: "SysSettings",
    data() {
        return {
            form: {
                sysName: '',
                sysStatus: 2,
                sysType: 1,
                company: 0,
                customer: ''
            },
            sysStatusOptions: [
                {value: 0, text: 'Deactivated'},
                {value: 1, text: 'Suspended'},
                {value: 2, text: 'Active'}
            ],
            sysTypeOptions: [
                {value: 0, text: 'Self Managed'},
                {value: 1, text: 'Managed'}
            ],
            compSelDisable: false
        }
    },
    created() {
      this.$store.dispatch('getCompList');
      if (this.$store.getters.role !== roles.roles.superUser) {
          if (this.$store.getters.company !== null)
            this.form.company = this.$store.getters.company;
          this.compSelDisable = true;
      }
      else {
          if (this.$store.getters.adminSelectedUser !== null) {
              if (this.$store.getters.adminSelectedUser.company !== null)
                  this.form.company = this.$store.getters.adminSelectedUser.company;
          }
      }
        this.$store.commit('storeTemplateCompSel', this.form.company);
    },
    mounted() {
        EventBus.$on("UpdateDeviceSys", () => {
            const sys = this.$store.getters.deviceSys;
            this.form.customer = sys.customer;
            this.form.sysStatus = sys.sysStatus;
            this.form.sysType = sys.sysType;
            if (sys.company !== null)
                this.form.company = sys.company
            this.form.sysName = sys.sysName;
        });
    },
    beforeDestroy() {
      EventBus.$off("UpdateDeviceSys");
    },
    methods: {
        compSelChange(value) {
            this.$store.commit('storeTemplateCompSel', value);
            EventBus.$emit("ConfigCompChange");
        },
        onFormChange() {
            this.$store.commit('storeDeviceSys', this.form);
        },
        nameFormatter(value) {
          return value.substr(0, 24);
        }
    },
    computed: {
        sysCompOptions() {
            const cList = this.$store.getters.compList;
            let tList = [];
            if (cList !== null) {
                for (let i = 0; i < cList.length; i++) {
                    tList.push({
                        value: i,
                        text: cList[i].name
                    });
                }
            }
            return tList;
        }
    }
}
</script>

<style scoped>

</style>
