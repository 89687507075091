<template>
    <div>
        <h3>Configure New Device</h3>
        <hr>
        Device ID: {{$route.params.id}}
        <hr>
        <div>
            <b-container class="text-center">
                <b-row
                    no-gutters
                    cols="1"
                    cols-sm="1"
                    cols-md="2"
                    cols-lg="3"
                    align-v="start"
                    align-h="center"
                >
                    <b-col>
                        <b-card class="mb-1" style="min-width: 18rem">
                            <b-form-group label="Assign to User:">
                                <b-row cols="2">
                                    <b-col>
                                        <b-form-input disabled v-model="selUserName"></b-form-input>
                                    </b-col>
                                    <b-col>
                                        <b-button @click="selectUser">Select User</b-button>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <b-container>
            <b-row
                no-gutters
                cols="1"
                cols-sm="1"
                cols-md="2"
                cols-lg="3"
                align-v="start"
                align-h="between"
            >
                <div>
                    <b-col>
                        <b-card class="mb-1">
                            <sys-settings></sys-settings>
                        </b-card>
                    </b-col>
                </div>
                <div v-if="devType === 'InsectControl'">
                    <b-col>
                        <b-card class="mb-1">
                            <cfg-settings></cfg-settings>
                        </b-card>
                    </b-col>
                </div>
                <div v-else-if="devType === 'CoolMist'">
                    <b-col>
                        <b-card class="mb-1">
                            <cfg-settings-cool-mist></cfg-settings-cool-mist>
                        </b-card>
                    </b-col>
                </div>
                <div>
                    <b-card class="mb-1 ml-2 mr-1">
                        <device-location></device-location>
                    </b-card>
                </div>
                <div v-if="devType === 'InsectControl' && !isTankless">
                    <b-col>
                        <b-card class="mb-1">
                            <b-form inline>
                                <label class="w-75" for="sys-tankfill">Initial Tank Fill:</label>
                                <b-form-input
                                    id="sys-tankfill"
                                    class="w-25"
                                    type="number"
                                    v-model="tankfill"
                                    required
                                ></b-form-input>
                            </b-form>
                        </b-card>
                    </b-col>
                </div>
                <div>
                    <b-card header="Device Users" class="mb-1">
                        <device-users></device-users>
                    </b-card>
                </div>
                <div>
                    <b-card header="Schedule Templates" class="mb-1 ml-2 mr-1">
                        <sch-template></sch-template>
                    </b-card>
                </div>
            </b-row>
        </b-container>
        <div>
            <b-modal
                ref="user-modal"
                hide-footer
                centered
                title="Select User"
            >
                <user-list-simple></user-list-simple>
                <hr>
                <b-container class="text-center">
                    <b-row cols="1">
                        <b-col>
                            <b-button variant="success" block @click="userOK">OK</b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-modal>
        </div>
        <hr>
        <div class="text-center">
            <b-button variant="success" @click="configureDevice">Configure Device</b-button>
        </div>
    </div>
</template>

<script>
import UserListSimple from "@/components/AdminModules/Users/UserListSimple";
import SysSettings from "@/components/AdminModules/Devices/SysSettings";
import CfgSettings from "@/components/AdminModules/Devices/CfgSettings";
import CfgSettingsCoolMist from "@/components/AdminModules/Devices/CfgSettingsCoolMist";
import DeviceUsers from "@/components/AdminModules/Devices/DeviceUsers";
import SchTemplate from "@/components/AdminModules/Devices/SchTemplate";
import DeviceLocation from "@/components/AdminModules/Devices/DeviceLocation";
import {EventBus} from "@/plugins/event-bus";

export default {
    name: "ConfigureNewDevice",
    components: {
        UserListSimple,
        SysSettings,
        CfgSettings,
        CfgSettingsCoolMist,
        DeviceUsers,
        SchTemplate,
        DeviceLocation
    },
    data() {
        return {
            tankfill: 55,
            devId: null,
            devType: ""
        }
    },
    created() {
        this.devId = this.$route.params.id;
        this.devType = this.devIdToDevType(this.devId);
    },
    mounted() {
        if (this.devType === "InsectControl") {
            EventBus.$emit("NewDeviceConfig");
        }
        else if (this.devType === "CoolMist") {
            EventBus.$emit("NewDeviceCfgCool");
        }
    },
    methods: {
        devIdToDevType(value) {
            if (this.$store.getters.adminDevList.length !== 0) {
                const devList = this.$store.getters.adminDevList;
                for (let i = 0; i < devList.length; i++) {
                    if (devList[i].deviceId === value)
                        return devList[i].deviceType;
                }
            }
            return 'None';
        },
        selectUser() {
            this.$refs['user-modal'].show();
        },
        userOK() {
            this.$refs['user-modal'].hide();
        },
        async setupConfig()
        {
            const userId = this.$store.getters.adminSelectedUser.id;
            const geoData = this.$store.getters.deviceGeo;
            const devGeo = {
                id: this.devId,
                userId: userId,
                ip: '',
                useAddr: geoData.useAddr,
                lat: geoData.lat,
                lon: geoData.lon,
                street: geoData.street,
                city: geoData.city,
                state: geoData.state,
                zipcode: geoData.zipcode
            };

            let result = "Proc";
            // Cfg & Sys Settings
            //console.log(this.$store.getters.deviceCfg);
            await this.$store.dispatch('setDeviceCfg', {
                id: this.devId,
                userId: userId,
                cfg: this.$store.getters.deviceCfg
            });
            await this.$store.dispatch('setDeviceSys', {
                id: this.devId,
                userId: userId,
                cfg: this.$store.getters.deviceSys
            });

            if (!this.isTankless) {
                // Set Tank Fill
                await this.$store.dispatch('setDeviceInfo', {
                    id: this.devId,
                    userId: userId,
                    tankFill: {tank: this.tankfill}
                });
            }

            // Device Users
            await this.$store.dispatch('setDeviceUsers', {
                id: this.devId,
                userId: userId,
                users: this.$store.getters.deviceUsers
            });

            // Schedule Template Selecion
            let setupSch = [];
            const tmplsched = this.$store.getters.selectedTemplates;
            for (let i = 0; i < tmplsched.length; i++) {
                for (let j = 0; j < tmplsched[i].sch.length; j++) {
                    setupSch.push(tmplsched[i].sch[j]);
                }
            }

            await this.$store.dispatch('setDeviceScheduleOther', {
                id: this.devId,
                userId: userId,
                sch: setupSch
            });

            // Empty Suspend Schedule
            await this.$store.dispatch('setDeviceSuspendOther', {
                id: this.devId,
                userId: userId,
                sus: []
            });

            // Location
            if (devGeo.useAddr === "0")
                await this.$store.dispatch('setDeviceGeo', devGeo);
            else
                await this.$store.dispatch('setDeviceGeoGps', devGeo);

            // Push Settings
            await this.$store.dispatch('restoreDeviceSettings', {
                deviceId: this.devId,
                mode: 0
            });

            result = "Done";
            return result;
        },
        doConfiguration()
        {
          return new Promise((resolve, reject) => {
              try {
                  let result = this.setupConfig();
                  if (result)
                    return resolve(result);
                  else
                      return reject("Error");
              }
              catch (err) {
                  return reject(err);
              }
          })
        },
        configureDevice() {
            //console.log("Config Device");
            const username = this.$store.getters.adminSelectedUser.username;
            const userId = this.$store.getters.adminSelectedUser.id;
            const geoData = this.$store.getters.deviceGeo;
            const devGeo = {
                id: this.devId,
                userId: userId,
                ip: '',
                lat: geoData.lat,
                lon: geoData.lon,
                street: geoData.street,
                city: geoData.city,
                state: geoData.state,
                zipcode: geoData.zipcode
            };
            //console.log(geoData.useAddr);
            if (geoData.useAddr === "0") {
                this.$store.dispatch('verifyDeviceAddr', devGeo).then(() => {
                    // Add Device to User
                    this.$store.dispatch('addDeviceToUser', {id: this.devId, username: username}).then(() => {

                        this.doConfiguration().then(() => {
                            this.$bvToast.toast('New Device Configured', {
                                title: 'Configure New Device',
                                variant: 'success',
                                solid: true
                            });
                            setTimeout(() => {
                                this.$router.back();
                            }, 2500);
                        }).catch(() => {
                            this.$bvToast.toast('Error Configuring New Device', {
                                title: 'Configure New Device',
                                variant: 'danger',
                                solid: true
                            });
                        });
                    }).catch(() => {
                        this.$bvToast.toast('Error Configuring New Device', {
                            title: 'Configure New Device',
                            variant: 'danger',
                            solid: true
                        });
                    });
                }).catch(() => {
                    this.$bvToast.toast('Invalid Address', {
                        title: 'Configure New Device',
                        variant: 'danger',
                        solid: true
                    });
                });
            }
            else {
                // Add Device to User
                this.$store.dispatch('addDeviceToUser', {id: this.devId, username: username}).then(() => {
                    this.doConfiguration().then(() => {
                        this.$bvToast.toast('New Device Configured', {
                            title: 'Configure New Device',
                            variant: 'success',
                            solid: true
                        });
                        setTimeout(() => {
                            this.$router.back();
                        }, 2500);
                    }).catch(() => {
                        this.$bvToast.toast('Error Configuring New Device', {
                            title: 'Configure New Device',
                            variant: 'danger',
                            solid: true
                        });
                    });
                }).catch(() => {
                    this.$bvToast.toast('Error Configuring New Device', {
                        title: 'Configure New Device',
                        variant: 'danger',
                        solid: true
                    });
                });
            }
        }
    },
    computed: {
        selUserName() {
            if (this.$store.getters.adminSelectedUser !== null)
                return this.$store.getters.adminSelectedUser.username
            else
                return '';
        },
        isTankless() {
            if (this.$store.getters.deviceCfg !== null) {
                if ("tankless" in this.$store.getters.deviceCfg)
                    return this.$store.getters.deviceCfg.tankless;
                else
                    return false;
            }
            else
                return false;
        }
    },
}
</script>

<style scoped>

</style>
